import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Config, Config_app } from "../../styles/Config"
import { AiFillHeart, AiOutlineHeart }  from "react-icons/ai"
import { CiUser } from 'react-icons/ci'
import { BiSolidUser } from 'react-icons/bi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { IoHomeOutline, IoHome, IoSearchSharp, IoSearchOutline } from "react-icons/io5";
import { get_storage, set_storage } from '../../api/Service'


export const Navbar = ({op="service"}) => {

    const navigate = useNavigate()

    const [showPolitica, SetShowPolitica] = useState(false)

    const verifico_politica = async () => {
        const politica =  await get_storage("politica")
        
        if(politica !== true){
            SetShowPolitica(true)
        }
    }

    const acepto_politica = async ()=>{
        await set_storage('politica',true)
        SetShowPolitica(false)
    }

    useEffect(()=>{
        verifico_politica()
    },[])

    return(
        <>
            
            <div className="conteiner fixed-bottom">
                    <div className="row">
                        <div className="col-md-2 col-lg-4 d-none d-md-block">
                            &nbsp;
                        </div>
                        <div className="col-12 col-md-8 col-lg-4">
                            
                        {showPolitica&&<div className="container  d-flex flex-column justify-content-center align-items-center bg-white p-2" style={{backgroundColor:Config.bg_light, marginBottom: 70}}>

                        <label className="text-center p-2">
                        "Vi bruker informasjonskapsler (cookies) på nettstedet vårt for å forbedre din nettleseropplevelse."
                        <br />
                        <button className="btn mt-2" style={{backgroundColor:Config_app.bg_primary, color:Config_app.color_light}} onClick={()=>acepto_politica()} >Godta</button>
                        </label>
                        </div>}

                        </div>
                        <div className="col-md-2 col-lg-4 d-none d-md-block">
                            &nbsp;
                        </div>

                    </div>
                </div>
            <nav className=" d-block d-md-none container d-flex flex-row justify-content-between justify-content-md-around align-items-center px-4 pt-3 fixed-bottom" style={{backgroundColor:Config.bg_light, maxWidth:Config.maxWidth}}>
                <div className="pointer mb-3" onClick={()=>navigate("/service")} style={{color:Config.color_primary, fontSize: Config.font_h2_size}} >
                    {op==="service"?<IoHome />:<IoHomeOutline />}
                </div>
                <div className="pointer mb-3" onClick={()=>navigate("/search")} style={{color:Config.color_primary, fontSize: Config.font_h2_size}} >
                    {op==="search"?<IoSearchSharp />:<IoSearchOutline />}
                </div>
                <div className="pointer mb-3" onClick={()=>navigate("/favorite")} style={{color:Config.color_primary, fontSize: Config.font_h2_size}} >
                    {op==="favorite"?<AiFillHeart />:<AiOutlineHeart />}
                </div>
                <div className="pointer mb-3" onClick={()=>navigate("/user")} style={{color:Config.color_primary, fontSize: Config.font_h2_size}} >
                    {op==="user"?<BiSolidUser />:<CiUser />}
                </div>
            </nav>
            <br />
            <br />
            <br />
        </>
    )

}

export const Navbar_2 = ({title="titulo", onClick}) => {



    return(
        <>
            <nav className="container d-flex flex-row justify-content-between align-items-center p-md-2 pt-3 pb-4 fixed-bottom" style={{backgroundColor:Config.bg_light, maxWidth:Config.maxWidth}}>
                <div style={{color:Config.color_light}} >
                    {title}
                </div>
                <div className="pointer" onClick={onClick} style={{color:Config.color_light, fontSize: Config.font_h2_size}} >
                    <AiOutlineCloseCircle />
                </div>
            </nav>
            <br />
            <br />
            <br />
            <br />
        </>
    )

}