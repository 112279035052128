import React from 'react'
import { useEffect, useState } from "react";
import { Navbar } from "../components/navbar/Navbar";
import { collection, onSnapshot, query, orderBy, where, doc } from "firebase/firestore";

import { db } from "../api/firebase";
import { ItemComplete } from "../components/ItemComplete";
import { Config, Config_app } from "../styles/Config";
import { SlArrowLeft } from 'react-icons/sl'
import { useNavigate, useSearchParams } from "react-router-dom";
import { get_storage } from "../api/Service";
import { Banner } from '../components/Banner';

export const Builds = ({ navigation}) => {

    const navigate = useNavigate()
    const [builds, setBuilds] = useState([])
    const [title, setTitle] = useState("hola")
    const [queryParameters] = useSearchParams()
    const [showAlert, setShowAlert] = useState(false)

    const cargo_datos = async (user, service) => {

        let q = query(collection(db, "builds"), where("estado", "==", true), where("services", "array-contains", {id:service.id}), orderBy("order"));


        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const filas = [];
            querySnapshot.forEach((doc) => {

                let newDoc = doc.data()
                newDoc.user = user
                filas.push(newDoc);
            
            });

            setBuilds(filas)
            
            
        });

    }

    const cargo_servicio = async (user, serviceId) => {

        const unsub = onSnapshot(doc(db, "services", serviceId), (doc) => {

            if(doc.exists()){

                let service = doc.data()
                service.id = doc.id

                setTitle((service.translated)?service.translated[0][user.language]:service.name)
   
                cargo_datos(user, service)
                

            }
            else{
                navigate('/')
            }

        });

    }

    const cargo_usuario = async (token, serviceId) => {

        const unsub = onSnapshot(doc(db, "users", token), (doc) => {

            if(doc.exists()){

                let user = doc.data()
                user.id = doc.id
   
                cargo_servicio(user, serviceId)
                

            }
            else{
                navigate('/')
            }

        });

    }

    const verifico = async () => {
        
        try {
            const serviceId = queryParameters.get("service")

            if(serviceId){
                
                let token_storage = await get_storage('token')
                if(token_storage == false){ navigate('/') }
                
                cargo_usuario(token_storage, serviceId)
            }
            else{
                navigate('/')
            }

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(()=>{ verifico() },[navigation])

    return(<>
        <Banner op="builds" titulo={title} />
        <div className="conteiner" >
            <div className="row">
                <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                    &nbsp;
                </div>
                <div className="col-12 col-md-8 col-lg-4 bg-white vh-100">
                <div className="container-fluid d-flex flex-row justify-content-between align-items-center p-md-2 py-3 w-100" >
                    <div className="pointer" onClick={()=>navigate('/')}><SlArrowLeft /></div>
                    <div className="text-end" style={{color:Config.color_primary, fontSize:Config_app.font_h4_size}}>{title}</div>
                </div>
                    
                {
                    builds.map((item,index)=><ItemComplete key={index} item={item} />)
                }

                </div>
                <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                    &nbsp;
                </div>

            </div>
        </div>
                <Navbar op="search" />

            </>
    )
}