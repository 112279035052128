import React from 'react'
import { useEffect, useState } from "react";
import { Navbar } from "../components/navbar/Navbar"
import { db } from "../api/firebase";
import { get_storage } from "../api/Service";
import { collection, doc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { Config_app } from "../styles/Config";
import { useNavigate } from "react-router-dom";
import { ItemComplete } from "../components/ItemComplete";
import { Banner } from '../components/Banner';
//import { AdsComponent } from '../components/Googleads';

export const Favorite = ({navigation}) => {

    const navigate = useNavigate()

    const [isLogin,setIsLogin] = useState(false)

    const [builds, setBuilds] = useState([])
    const [title, setTitle] = useState("Favorite")

    const cargo_datos = async (user) => {

        try {
            
            if(user && user.favorite && user.favorite.length > 0){
                let q = query(collection(db, "builds"), where("estado", "==", true), where("id", "in", user.favorite), orderBy("order"));

                onSnapshot(q, (querySnapshot) => {

                    const filas = [];
                    querySnapshot.forEach((doc) => {

                        let newDoc = doc.data()
                        newDoc.user = user
                        filas.push(newDoc);
                    
                    });

                    setBuilds(filas)
                    
                    
                });
            }
            else{
                setBuilds([])
            }

        } catch (error) {
            console.log(error)
        }


    }

    const cargo_usuario = async (token) => {

        onSnapshot(doc(db, "users", token), (doc) => {

            if(doc.exists()){

                let user = doc.data()
                user.id = doc.id
                setTitle(user.labels.favorito_titulo)
                cargo_datos(user)

            }
            else{
                navigate('/')

            }

        });

    }


    const verifico = async () => {
        
        try {
            let token_storage = await get_storage('token')
            if(token_storage === false){ navigate('/') }
            
            cargo_usuario(token_storage)

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(()=>{

        const login = localStorage.getItem('isLogin')
        
        if(login){ 
            setIsLogin(localStorage.getItem('isLogin')) 
            verifico() 
        }

    },[navigation])

    if(isLogin==false){

        return(
            <>
            <Banner
                op="favorite" 
                titulo={"favorite"}
            />
            <div className="conteiner">
                <div className="row">
                    <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                        &bnsp;
                    </div>
                    <div className="col-12 col-md-8 col-lg-4 bg-white vh-100">
                        
                        <div className="row mt-5 pt-5">
                            <div className="col-12 mb-4 text-center">
                                <img className="img-fluid rounded" width={150} src={Config_app.logo_app_primary} />
                            </div>
                            <div className="col-12 mb-4 text-center">
                                <h3>{Config_app.label_inicio_title_presentation}</h3>
                            </div>
                            <div className="col-12 mb-4 text-center">
                            <button className="btn btn-success mb-3" onClick={()=>navigate('/user')}>{"Register"}</button><br />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                        &nbsp;
                    </div>

                </div>
            </div>
            </>
        )

    }
    else{
        return(
            <>
            <Banner
                op="favorite" 
                titulo={"favorite"}
            />
            <div className="conteiner">
                <div className="row">
                    <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                        &nbsp;
                    </div>
                    <div className="col-12 col-md-8 col-lg-4 bg-white vh-100">
                        
                    {
                        builds.map((item,index)=><ItemComplete key={index} item={item} />)
                    }

                    </div>
                    <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                        &nbsp;
                    </div>

                </div>
            </div>
            <Navbar op="favorite" />

            </>
        )
    }
}