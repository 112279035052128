import React from 'react'
import { Config, Config_app } from "../styles/Config"

export const ItemImgText = ({url="", titulo="", onClick}) => {
    return(
                <div className="d-flex flex-row justify-content-between align-items-center py-4 border-bottom pointer bg-white" onClick={onClick} style={{paddingLeft:20, paddingRight:20, gap:10}}>
                    <img alt='Hjelposlo' src={url} className="rounded " style={{width:Config_app.image_size_h2}} />
                    <div className="text-end" style={{color:Config.color_primary, fontSize:Config_app.font_h4_size}}>{titulo}</div>
                </div>
    )
}

export const ItemImgText_oculto = ({url="", titulo="", onClick}) => {
    return(
                <div className=" d-none d-flex flex-column justify-content-center align-items-center py-4 border-bottom pointer bg-white w-100" onClick={onClick} style={{paddingLeft:20, paddingRight:20, gap:10}}>
                    <img alt='Hjelposlo' src={url} className="rounded " style={{width:Config_app.image_size_h2}} />
                    <div style={{color:Config.color_primary, fontSize:Config_app.font_h4_size, textAlign:'justify'}}>{titulo}</div>
                </div>
    )
}