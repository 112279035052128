import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Config, Config_app } from "../styles/Config"
import { IoHome, IoHomeOutline, IoLanguage, IoLanguageOutline, IoSearchOutline, IoSearchSharp } from 'react-icons/io5'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { CiUser } from 'react-icons/ci'
import { BiSolidSearch, BiSolidUser } from 'react-icons/bi'
import { BsArrowLeftCircle } from 'react-icons/bs'
import { MdInstallDesktop } from 'react-icons/md'

import { useEffect } from 'react'

export const Banner = ({ titulo="", quetion="", onpresstranslate, op="services", bienvenidos="Velkommen"}) => {

    const navigate = useNavigate()

    //const [instalar,setInstalar] = useState(false)
   

    useEffect(()=>{

    },[navigate])

    return(
        <>
        <div className="fixed-top d-block d-md-none">
            <div className="container-fluid d-flex flex-column justify-content-between align-items-start w-100" style={{backgroundColor:Config_app.bg_primary, paddingTop:5, fontSize:15, maxWidth:Config.maxWidth}} >
                {op=="services"&&<div className="d-flex flex-row justify-content-start align-items-center" style={{color:Config_app.color_light}}>{titulo}</div>}
                <div className="d-flex flex-row justify-content-between align-items-center w-100" style={{backgroundColor:Config_app.bg_primary, marginTop:5, marginBottom:15, paddingLeft:10, paddingRight:10}}>
                    {op=="services"&&<img alt='Hjelposlo' src={Config_app.logo_app_secondary} className="rounded " style={{width:30}} />}
                    {op=="services"&&
                        <div className='d-flex flex-column justify-content-center'>
                            <img alt='Hjelposlo' src={Config_app.logo_app_install} className="rounded pointer" style={{width:20}} onClick={()=>navigate("Install")} />
                            <span className='text-white text-center' style={{fontSize:10}}>App</span>
                        </div>
                    }
                    {op=="services"&&<img alt='Hjelposlo' src={Config_app.logo_app_translate} className="rounded pointer" style={{width:25}} onClick={()=>onpresstranslate()} />}
                    {(op=="search" || op=="favorite" || op=="user" || op=="install")&&<BsArrowLeftCircle className='text-white' onClick={()=>navigate("/")}/>}
                    {(op=="search")&&<span className='text-white'><IoSearchSharp /></span>}
                    {(op=="favorite")&&<span className='text-white'><AiFillHeart /></span>}
                    {(op=="user")&&<span className='text-white'><BiSolidUser /></span>}
                    {(op=="install")&&<span className='text-white'><MdInstallDesktop /></span>}
                    
                </div>
            </div>
            {op=="services"&&<div className="container-fluid d-flex flex-row justify-content-start align-items-center" style={{color:Config_app.color_primary, backgroundColor:Config_app.bg_light, paddingTop:20, paddingBottom:10, paddingLeft:10, paddingRight:10, fontSize:Config_app.font_h3_size, maxWidth:Config.maxWidth}}>{quetion}</div>}
        </div>
        <div className="conteiner p-0 d-none d-md-block fixed-top"  style={{background:Config_app.bg_primary}} >
            <div className="row">
                <div className="col-md-2 col-lg-4 d-none d-md-block">
                    &nbsp;
                </div>
                <div className="col-12 col-md-8 col-lg-4 text-end text-white pe-4 pt-1">
                    {bienvenidos}
                </div>
                <div className="col-md-2 col-lg-4 d-none d-md-block">
                    &nbsp;
                </div>

            </div>
        </div>
        <div className="conteiner p-0 d-none d-md-block fixed-top" style={{background:Config_app.bg_primary, marginTop:25}} >
            <div className="row">
                <div className="col-md-2 col-lg-4 d-none d-md-block">
                    &nbsp;
                </div>
                <div className="col-12 col-md-8 col-lg-4 text-end">
                    <div className='d-flex justify-content-between align-items-center gap-3 py-1'>
                        <div className='d-flex flex-column justify-content-center align-items-center pointer' 
                            onClick={()=>navigate("/")}
                        >
                            <img id='installApp' alt='Hjelposlo' src={Config_app.logo_app_secondary} className="rounded mx-auto" style={{width:Config_app.image_size_h2, marginLeft:-25}} />
                            <span className='text-white' >Hjelposlo</span>
                        </div>
                        <div className='d-flex justify-content-end align-items-center gb-white'>

                            <span className="nav-link text-white pointer ms-4"  onClick={()=>navigate("/services")}>{op=="services"?<IoHome  />:<IoHomeOutline  />}</span>

                            <span className="nav-link text-white pointer ms-4"  onClick={()=>navigate("/search")}>{op=="search"?<BiSolidSearch  />:<IoSearchOutline  />}</span>
                        
                            <span className="nav-link text-white pointer ms-4"  onClick={()=>navigate("/favorite")}>{op=="favorite"?<AiFillHeart  />:<AiOutlineHeart  />}</span>
                        
                            <span className="nav-link text-white pointer ms-4"  onClick={()=>navigate("/user")}>{op=="user"?<BiSolidUser  />:<CiUser  />}</span>
                    
                            {op=="services"&&<span className="nav-link text-white pointer ms-4"  onClick={()=>onpresstranslate()}>{op=="translate"?<IoLanguage  />:<IoLanguageOutline  />}</span>}
                            {<span className="nav-link text-white pointer ms-4"  onClick={()=>navigate("Install")}>{<MdInstallDesktop />}</span>}
                            
                        </div>
                    </div>
                </div>
                <div className="col-md-2 col-lg-4 d-none d-md-block">
                    &nbsp;
                </div>

            </div>
        </div>
        
        <br className="d-block d-md-none" style={{backgroundColor:Config.bg_secondary}} />
        <br className="d-block d-md-none" style={{backgroundColor:Config.bg_secondary}} />
        {op=="services"&&<br className="d-block d-md-none" style={{backgroundColor:Config.bg_secondary}} />}
        {op=="services"&&<br className="d-block d-md-none" style={{backgroundColor:Config.bg_secondary}} />}
        {op=="services"&&<br className="d-block d-md-none" style={{backgroundColor:Config.bg_secondary}} />}
        {op=="services"&&<br className="d-block d-md-none" style={{backgroundColor:Config.bg_secondary}} />}
        <br className="d-none d-md-block" style={{backgroundColor:Config.bg_secondary}} />
        <br className="d-none d-md-block" style={{backgroundColor:Config.bg_secondary}} />
        <br className="d-none d-md-block" style={{backgroundColor:Config.bg_secondary}} />
        <br className="d-none d-md-block" style={{backgroundColor:Config.bg_secondary}} />
        </>
    )
}

export const Banner_2 = ({ titulo="", quetion="", onpresstranslate, op="services", bienvenidos="Velkommen"}) => {

    const navigate = useNavigate()

    const [instalar,setInstalar] = useState(false)
   

    useEffect(()=>{

    },[navigate])

    return(
        <>
            <div className='row py-2 fixed-top'  style={{backgroundColor:Config_app.bg_primary}}>
                <div className='col-sm-2 d-none d-sm-flex'>&nbsp;</div>
                <div className='col-12 col-sm-8 text-end text-white'>
                    {titulo}
                </div>
                <div className='col-sm-2 d-none d-sm-flex'>&nbsp;</div>
            </div>
        </>
    )
}