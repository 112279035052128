import React from 'react'
import { useEffect, useState } from "react"
import { Navbar } from "../components/navbar/Navbar"
import { ItemImgText } from "../components/ItemImgText"
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, query, orderBy, where } from "firebase/firestore";
import { db } from "../api/firebase";


export const Translate = () => {

    const [languages, setLanguages] = useState([{image:"https://img.freepik.com/vector-premium/imagen-dibujos-animados-hongo-palabra-hongo_587001-200.jpg?w=2000",name:"Luis Perez"}])

    const logo_translate = "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_register.png?alt=media&token=665cc76e-dffe-401f-bf54-995c9863c9d9";

    const navigation = useNavigate()

    const cargo_datos = async (user) => {

        let q = query(collection(db, "languages"), where("estado", "==", true), orderBy("order"));

        onSnapshot(q, (querySnapshot) => {

            const filas = [];
            querySnapshot.forEach((doc) => {

                let newDoc = doc.data()
                newDoc.user = user
                filas.push(newDoc);
            
            });

            //set_storage('services', filas)
            setLanguages(filas)
            
            
        });

    }

    useEffect(()=>{
        cargo_datos()
    },[navigation])

    return(
        <div className="container-fluid">
            <Navbar op="translate" />
            {
                languages.map((item,index)=><ItemImgText key={index} url={logo_translate} titulo={item.name} />)
            }
        </div>
    )
}