import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Service } from "./pages/Service";
import { Search } from "./pages/Search";
import { Favorite } from "./pages/Favorite";
import { User } from "./pages/User";
import { Translate } from "./pages/Translate";
import { Builds } from "./pages/Builds";
import { Instalarapp } from './pages/Instalarapp';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Service />} />
            <Route path="Builds" element={<Builds />} />
            <Route path="Search" element={<Search />} />
            <Route path="Favorite" element={<Favorite />} />
            <Route path="User" element={<User />} />
            <Route path="Translate" element={<Translate />} />
            <Route path="Install" element={<Instalarapp />} />
            <Route path="*" element={<Service />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
