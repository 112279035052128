import React from 'react'
import { Config, Config_app } from "../styles/Config"
import { AiOutlinePlusCircle, AiOutlineMail, AiOutlineMinusCircle } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs'
import { AiFillHeart, AiOutlineHeart }  from "react-icons/ai"
import { FiMapPin, FiExternalLink } from 'react-icons/fi'
import { useEffect, useState } from "react"
import { BsShare } from 'react-icons/bs'
import { useNavigate } from "react-router-dom"
import { doc, setDoc } from "firebase/firestore";
import { db } from "../api/firebase"

export const ItemComplete = ({item}) => {

    const navigate = useNavigate()
    const [showDetail, setShowDetail] = useState(false)
    const title = (item.translated)?item.translated[0][item.user.language]:item.title
    const content = (item.translated)?item.translated[1][item.user.language]:item.content

    const [message, setMensaje] = useState("")
    const [favorito, setFavorito] = useState(false)

    const pressFavorite = async (item) => {

        try {
    
    
          if(item.user.isLogin === true){
            
            const favoritoTemp = favorito
            let userTemp = item.user
            
            if(favoritoTemp === true){
              //lo quito
              let temp = []
              for (let index = 0; index < userTemp.favorite.length; index++) {
                const element = userTemp.favorite[index];
    
                if(element !== item.id){
                  temp.push(element)
                } 
                
              }
              userTemp.favorite = temp
    
            }
            else{
              //lo agrego
              userTemp.favorite.push(item.id)
              
            }
    
            const userRef = doc(db,"users", item.user.id)
            await setDoc(userRef, userTemp)
    
            setFavorito(!favorito)
      
          }
          else{
      
            twoOptionAlertHandler(message)
          
          }
    
        } catch (error) {
          console.log(error)
        }
      
    }
      
    const twoOptionAlertHandler = () => {
      
      let result = window.confirm(message);

      if(result){ navigate('/user') }
      

    };

    const verifico = async () => {
        
        try {
    
            if(item.user){
    
              setMensaje(item.user.labels.favorito_mensaje)
      
              if(item.user.favorite){
                let band = false
                for (let index = 0; index < item.user.favorite.length; index++) {
                  const element = item.user.favorite[index];
                  if(element === item.id){
                    band = true
                    index = item.user.favorite.length
                  }
                  
                }
                setFavorito(band)
              }
    
            }
    
    
        } catch (error) {
            console.log(error)
        }
    
    }

    useEffect(() => { verifico() },[]);

    return(
        <div className="border-bottom" style={{paddingLeft:20, paddingRight:20, gap:10}}>
        <div className="d-flex flex-row justify-content-between align-items-center py-3" >
            <div className="d-flex flex-column align-items-center" style={{width:80}}>
                <img alt='Hjelposlo' src={item.image} className="rounded " style={{width:55}} />
                <div className="text-end pointer" style={{color:Config_app.color_secondary, fontSize:Config_app.font_h5_size}}>{item.name}</div>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-end">
                <div className="text-end" style={{color:Config_app.color_secondary, maxWidth:290}}>{title}</div>
                <div className="d-flex flex-row justify-content-end align-items-center mt-3">
                    {item.content!=="<->"&&<i className="ms-4 pointer" style={{fontSize:Config_app.icon_build_size}} onClick={()=>setShowDetail(!showDetail)}>{showDetail?<AiOutlineMinusCircle />:<AiOutlinePlusCircle />}</i>}
                    {item.phone!=="<->"&&<i className="ms-4 pointer" style={{fontSize:Config_app.icon_build_size}}><BsTelephone onClick={() => window.location = 'tel:' + item.phone} /></i>}
                    {item.email!=="<->"&&<i className="ms-4 pointer" style={{fontSize:Config_app.icon_build_size}}><AiOutlineMail onClick={() => window.location = 'mailto:' + item.email} /></i>}
                    <i className="ms-4 pointer" onClick={()=>pressFavorite(item)} style={{fontSize:Config_app.icon_build_size}}>{favorito===false?<AiOutlineHeart />:<AiFillHeart />}</i>
                    {item.mapLink!=="<->"&&<i className="ms-4 pointer" style={{fontSize:Config_app.icon_build_size}}><FiMapPin onClick={()=>window.open(item.mapLink,"_blank")} /></i>}
                    {item.registerLink!=="<->"&&<i className="ms-4 pointer" style={{fontSize:Config_app.icon_build_size}}><FiExternalLink onClick={()=>window.open(item.registerLink,"_blank")} /></i>}
                </div>
            </div>
        </div>
        {showDetail&&
        <div className="d-flex flex-column mb-3">
            <div>{content.split(/\n/g).map((str,index)=><p key={index} style={{textAlign:"justify"}}>{str}</p>)}</div>
            <div className="d-flex justify-content-center align-items-center mt-3">
                <i className="ms-4 pointer" style={{fontSize:Config.font_h1_size}}><BsShare onClick={()=>navigator.share({url:window.location, title:"Hjelposlo", text:"VI BLOMSTRER SAMMEN"}) } /></i>
            </div>
        </div>
        }
        </div>
    )
}