import React, { useEffect, useState } from "react"
import { Navbar } from "../components/navbar/Navbar"
import { ItemImgText, ItemImgText_oculto } from "../components/ItemImgText"
import { collection, onSnapshot, query, orderBy, where, doc, setDoc, limit } from "firebase/firestore";
import { db } from "../api/firebase";
import { Banner, Banner_2 } from "../components/Banner";
import { get_labelsapp, get_storage, registrarUser, set_storage } from '../api/Service'
import { Config_app } from "../styles/Config";
import { useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle } from 'react-icons/ai';
//import { AdsComponent } from "../components/Googleads";
import { Espacio } from "../components/Espacio";


export const Service = ({route, navigation}) => {

    const navigate = useNavigate()

    const [labelWelcome, setLabelWelcome] = useState("");
    const [labelQuestion, setLabelQuestion] = useState("");
    const [languages, setLanguages] = useState([]);
    const [languagesTemp, setLanguagesTemp] = useState([]);
    const [estado, setEstado] = useState(0);
    
    const [token, setToken] = useState("");
    const [user, setUser] = useState("");
    const [services, setServices] = useState([]);
    const [builds, setBuilds] = useState([])
    const [placeholder, setPlaceholder] = useState("")

    const filterBuilds = (text = "") => {

        const newData = languages.filter(row =>{
          return row.name.toLowerCase().includes(text.toLowerCase().split())
        })
    
        setLanguagesTemp(newData)
      
    }

    const cargo_datos2 = async (user) => {

        let q = query(collection(db, "builds"), where("estado", "==", true), orderBy("order"), limit(50));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const filas = [];

            querySnapshot.forEach((doc) => {

                let newDoc = doc.data()
                newDoc.user = user

                newDoc.diccionario = newDoc.name;

                filas.push(newDoc);
            
            });

            setBuilds(filas)
            console.log(filas)

            return unsubscribe
            
        });

    }

    const cargo_datos = async (user) => {

        let q = query(collection(db, "services"), where("estado", "==", true), orderBy("order"));

        onSnapshot(q, (querySnapshot) => {

            const filas = [];
            querySnapshot.forEach((doc) => {

                let newDoc = doc.data()
                newDoc.user = user
                filas.push(newDoc);
            
            });

            setServices(filas)
            set_storage('services', filas)
            //cargo_datos2()
            
        });


    }

    const cargo_lenguajes = async () => {

        let q = query(collection(db, "languages"), where("estado", "==", true), orderBy("order"));

        onSnapshot(q, (querySnapshot) => {

            const filas = [];
            querySnapshot.forEach((doc) => {

                let newDoc = doc.data()
                newDoc.id = doc.id
                filas.push(newDoc);
            
            });

            setLanguages(filas)
            setLanguagesTemp(filas)
            console.log(filas)
            
        });

    }

    const setear_lenguaje = async (lan) => {

       
        set_storage('language', lan.code)
        
        const labels = await get_labelsapp(lan.code)
        let newDoc = user;
        newDoc.labels = labels
        newDoc.language = lan.code
        
        await setDoc(doc(db,'users', newDoc.id), newDoc)

        setEstado(0)
    }

    const go_to_builds = async (service) =>{

        const element = {
            service:service,
            token: token,
            user: user,
            title: (service.translated)?service.translated[0][user.language]:service.name
        }

        navigate("Builds?service=" + service.id)

    }

    const cargo_usuario = async (token) => {

        onSnapshot(doc(db, "users", token), (doc) => {

            if(doc.exists()){

                let user = doc.data()
                user.id = doc.id
                setUser(user)
                setLabelWelcome(user?.labels?.inicio_welcome)    
                setLabelQuestion(user?.labels?.inicio_question)    
                setPlaceholder(user?.labels?.buscar_placeholder)
                cargo_datos(user)
                cargo_lenguajes()
                setEstado(0)

            }
            else{
                
                token_invalido()
            }

        });

    }

    const token_invalido = async () => {
        
        const token_storage = await registrarUser()
        setToken(token_storage)
        cargo_usuario( token_storage)
    
    }

    const verifico = async () => {
        
        try {
            let token_storage = await get_storage('token')
            if(token_storage == false){ token_storage = await registrarUser() }
          
            cargo_usuario(token_storage)

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(()=>{ verifico() },[navigation])

    if(estado == 0){

        return(
            <div className="container-fluid">
                <Banner
                    titulo={labelWelcome} 
                    quetion={labelQuestion} 
                    op="services"
                    onpresstranslate={()=>{ setEstado(1) }} 
                />
                
                <div className="row">
                    <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                        &nbsp;
                    </div>
                    <div className="col-12 col-md-8 col-lg-4">
                        
                        {
                            services.map((item,index)=>
                                    <ItemImgText key={index} url={item.image} titulo={(item.translated==false)?item.name:item.translated[0][item.user.language]} onClick={()=>go_to_builds(item)}  />)
                        }

                        {
                            builds.map((item,index)=><ItemImgText_oculto key={index} url={item.image} titulo={item.content} />)
                        }

                    </div>
                    <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                        &nbsp;
                    </div>

                </div>
                <Navbar op="service" />
            </div>
        )

    }

    if(estado == 1){

        return(
            <div>
                <div className="conteiner">
                    <div className="row">
                        <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                            &nbsp;
                        </div>
                        <div className="col-12 col-md-8 col-lg-4">
                        <div className="container-fluid d-flex flex-row justify-content-between align-items-center py-3" >
                            <input className="form-control" placeholder={placeholder} onChange={(e)=>filterBuilds(e.target.value)} />
                            <h2 className="pointer ms-3" onClick={()=>{
                                filterBuilds("")
                                setEstado(0)
                            }}><AiOutlineCloseCircle /></h2>
                        </div>
                        {
                            languagesTemp.map((item,index)=><ItemImgText key={index} url={Config_app.logo_app_primary} titulo={item.name} onClick={()=>setear_lenguaje(item)}  />)
                        }

                        </div>
                        <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                            &nbsp;
                        </div>

                    </div>
                </div>
                <Navbar op="service" />
            </div>
        )

    }
    
}