import React from 'react'
import { useEffect, useState } from "react";
import { Navbar } from "../components/navbar/Navbar";
import { collection, onSnapshot, query, orderBy, where, doc } from "firebase/firestore";
import { db } from "../api/firebase";
import { useNavigate } from "react-router-dom";
import { ItemComplete } from "../components/ItemComplete";
import { get_storage } from "../api/Service";
import { Banner } from '../components/Banner';
//import { AdsComponent } from '../components/Googleads';

export const Search = ({ navigation }) => {

    const navigate = useNavigate()

    const [builds, setBuilds] = useState([])
    const [buildsTemp, setBuildsTemp] = useState([])
    const [placeholder, setPlaceholder] = useState("")

    const filterBuilds = (text = "") => {

        const newData = builds.filter(row => {
            return row.diccionario.toLowerCase().includes(text.toLowerCase())
        })

        setBuildsTemp(newData)

    }

    const cargo_datos = async (user) => {

        const services = await get_storage('services')

        let q = query(collection(db, "builds"), where("estado", "==", true), orderBy("order"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const filas = [];
            let servicesText = ""

            querySnapshot.forEach((doc) => {

                let newDoc = doc.data()
                newDoc.user = user

                for (let index = 0; index < services.length; index++) {

                    const service = services[index];

                    for (let index2 = 0; index2 < newDoc.services.length; index2++) {

                        const serviceDoc = newDoc.services[index2];

                        if (serviceDoc.id === service.id) {
                            if (service.translated) {

                                servicesText += service.translated[0][user.language] + " "

                            }
                            else {

                                servicesText += service.name + " "

                            }
                        }

                    }

                }

                if (newDoc.translated) {

                    newDoc.diccionario = newDoc.name + " " + newDoc.translated[0][user.language] + " " + servicesText;

                }
                else {

                    newDoc.diccionario = newDoc.name + " " + newDoc.title + " " + servicesText;

                }

                filas.push(newDoc);

            });

            //set_storage('services', filas)
            setBuilds(filas)
            setBuildsTemp(filas)
            return unsubscribe

        });

    }

    const cargo_usuario = async (token) => {

        onSnapshot(doc(db, "users", token), (doc) => {

            if (doc.exists()) {

                let user = doc.data()
                user.id = doc.id
                // setUser(user)
                // setLanguage(user.language)
                //setLabelWelcome(user.labels.inicio_welcome)    
                //setLabelQuestion(user.labels.inicio_question)    
                // cargo_lenguajes()
                setPlaceholder(user.labels.buscar_placeholder)
                cargo_datos(user)


            }
            else {
                navigate('/')

            }

        });

    }


    const verifico = async () => {

        try {
            let token_storage = await get_storage('token')
            if (token_storage === false) { navigate('/') }

            cargo_usuario(token_storage)

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => { verifico() }, [navigation])

    return (<>
        <Banner op="search" titulo={placeholder} />
        <div className="conteiner" style={{ background: "#00000007" }}>
            <div className="row">
                <div className="col-md-2 col-lg-4 d-none d-md-block" style={{ background: "#00000007" }}>
                    &nbsp;
                </div>
                <div className="col-12 col-md-8 col-lg-4 bg-white">
                    <input className="form-control my-4" placeholder={placeholder} onChange={(e) => filterBuilds(e.target.value)} />
                    {
                        buildsTemp.map((item, index) => <ItemComplete key={index} item={item} />)
                    }

                </div>
                <div className="col-md-2 col-lg-4 d-none d-md-block" style={{ background: "#00000007" }}>
                    &nbsp;
                </div>

            </div>
        </div>
        <Navbar op="search" />

    </>
    )
}