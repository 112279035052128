export const Config = {

    bg_primary: "#204420",
    bg_primary_light: "#20442050",
    bg_secondary: "grey",
    bg_light: "#ffffff",
    bg_black: "#000000",
    color_primary: "#204420",
    color_primary_light: "#20442050",
    color_secondary: "grey",
    color_light: "#ffffff",
    color_black: "#000000",
    font_h1_size: 30,
    font_h2_size: 24,
    font_h3_size: 20,
    font_h4_size: 16,
    icon_buttom_size: 20, 
    image_item_list: 20,
    image_size_h1: 45,
    image_size_h2: 38,
    image_size_h3: 30,
    image_size_h4: 20,
    maxWidth: 600


}


export const Config_app = {
    bg_primary: "#022A02",
    bg_primary_light: "#022A0250",
    bg_secondary: "grey",
    bg_light: "#ffffff",
    bg_black: "#000000",
    color_primary: "#022A02",
    color_primary_light: "#022A0250",
    color_secondary: "grey",
    color_light: "#ffffff",
    color_black: "#000000",
    font_h1_size: 30,
    font_h2_size: 24,
    font_h3_size: 20,
    font_h4_size: 16,
    font_h5_size: 12,
    icon_buttom_size: 20, 
    icon_build_size: 22, 
    image_item_list: 20,
    image_size_h1: 50,
    image_size_h2: 38,
    image_size_h3: 30,
    image_size_h4: 20,
    image_size_presentation: 200,

    logo_app_secondary: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_blanco.jpg?alt=media",
    logo_app_primary: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_verde.PNG?alt=media",
    logo_app_install: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-dashboard-production.appspot.com/o/config_app%2Fdescargar.png?alt=media",
    logo_app_translate: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_translate.png?alt=media",
    logo_app_close: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_close.png?alt=media",
    logo_app_row_left: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_flecha_izquierda.png?alt=media",
    logo_app_register: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_register.png?alt=media",
    logo_app_email: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_email.png?alt=media",
    logo_app_phone: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_phone.png?alt=media",
    logo_app_map: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_map.png?alt=media",
    logo_app_favorite: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_favorite.png?alt=media",
    logo_app_favorite_select: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_favorite_select.png?alt=media",
    logo_app_more: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_more.png?alt=media",
    logo_app_less: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_less.png?alt=media",
    logo_app_country: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Flogo_app_pais.png?alt=media",
    logo_app_share: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Fcompartir.png?alt=media",
    logo_app_arbol_gif: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/config_app%2Farbolgif.gif?alt=media",
    
    logo_app_ios: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-dashboard-production.appspot.com/o/config_app%2Fios_icon.png?alt=media",
    img_app_ios: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-dashboard-production.appspot.com/o/config_app%2Fios.png?alt=media",
    logo_app_android: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-dashboard-production.appspot.com/o/config_app%2Fandroid_icon.png?alt=media",
    img_app_android: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-dashboard-production.appspot.com/o/config_app%2Fandroid.png?alt=media",
    logo_app_pc: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-dashboard-production.appspot.com/o/config_app%2Fpc_icon.png?alt=media",
    img_app_pc: "https://firebasestorage.googleapis.com/v0/b/hjelposlo-dashboard-production.appspot.com/o/config_app%2Fpc.png?alt=media",

    web_app_landing : "https://hjelposlolanding.web.app",

    language_app: "no",
    
    label_inicio_welcome: "Velkommen",
    label_inicio_question: "Hva trenger du?",
    label_inicio_title_presentation: "Hjelposlo",
    label_inicio_message_presentation: "VI BLOMSTRER SAMMEN",
    label_search_placeholder: "Se etter",
    label_search_message: "Du er ikke registrert. Vil du registrere deg?",
    label_search_option_yes: "Ja",
    label_search_option_no: "Nei",
    label_favorite_title: "Favoritter",
    label_favorite_message: "Du er ikke registrert ennå.",
    label_favorite_login: "Jeg ønsker å registrere meg",
    label_user_logout: "Gå ut",
    label_user_login: "Get into",
    label_user_message: "Du kan registrere deg med din e-post.",
    label_user_email: "e-post",
    label_user_name: "navnet ditt",
    label_user_code: "kode",
    label_user_send_code: "send kode",
    label_user_confirm_code: "bekrefte koden",
    label_user_email_message: "Din tilgangskode er",
    label_user_alert_message: "Existen datos faltantes.",
    label_user_option_yes: "Ja",
    label_user_title_country: "land",
    label_install_android: "Hvordan installere Hjelposlo på Android?",
    label_install_ios: "Hvordan installere Hjelposlo på Iphone?",
    label_install_pc: "Hvordan installere Hjelposlo på PC?",
}
