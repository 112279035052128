import React from 'react'
import { useEffect, useState } from "react";
import { Navbar } from "../components/navbar/Navbar"
//import {signInWithPopup, signOut} from "firebase/auth";
import { db } from "../api/firebase";
import { get_storage } from "../api/Service";
import { doc, onSnapshot} from "firebase/firestore";
//import { Timestamp, collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { Config_app } from "../styles/Config";
import { Banner } from '../components/Banner';
//import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
//import { MdInstallDesktop } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';

export const Instalarapp = ({route, navigation}) => {

    const [estado, setEstado] = useState(0)
    const [installAndroid, setInstallAndroid] = useState(Config_app.label_install_android)
    const [installIos, setInstallIos] = useState(Config_app.label_install_ios)
    const [installPc, setInstallPc] = useState(Config_app.label_install_pc)

    const navigate = useNavigate()

    const cargo_usuario = async (token) => {

        onSnapshot(doc(db, "users", token), (doc) => {

            if(doc.exists()){

                let user = doc.data()
                user.id = doc.id
                console.log(user.labels)
                if(user.labels.install_android){setInstallAndroid(user.labels.install_android)}
                if(user.labels.install_ios){setInstallIos(user.labels.install_ios)}
                if(user.labels.install_pc){setInstallPc(user.labels.install_pc)}  
                
            }
            else{
                navigate('/')

            }

        });

    }

    const verifico = async () => {
        
        try {
            let token_storage = await get_storage('token')
            if(token_storage == false){ navigate('/') }
            
            cargo_usuario(token_storage)

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(()=>{ verifico() },[navigation])

    if(estado==0){

        return(
            <>
                <Banner
                    op="install" 
                    titulo={"install"}
                />
                <div className="conteiner">
                    <div className="row">
                        <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                            &nbsp;
                        </div>
                        <div className="col-12 col-md-8 col-lg-4 text-center vh-100">
                            <div className='row'>
                                <div className='col-12 text-center py-4'>
                                    <div className='row'>
                                        <div className='col-12 text-center d-flex flex-row justify-content-around align-items-center'>
                                            <img alt='Hjelposlo' src={Config_app.logo_app_android} className="rounded pt-2 " style={{width:Config_app.image_size_h2}} />
                                            <label>{installAndroid}</label>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <img alt='Hjelposlo' src={Config_app.img_app_android} className="rounded pb-1 img-fluid"/>
                                        </div>
                                        <div className='col-12 text-center d-flex flex-row justify-content-around align-items-center'>
                                            <img alt='Hjelposlo' src={Config_app.logo_app_ios} className="rounded pt-2 " style={{width:Config_app.image_size_h2}} />
                                            <label>{installIos}</label>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <img alt='Hjelposlo' src={Config_app.img_app_ios} className="rounded pb-1 img-fluid"/>
                                        </div>
                                        <div className='col-12 text-center d-flex flex-row justify-content-around align-items-center'>
                                            <img alt='Hjelposlo' src={Config_app.logo_app_pc} className="rounded pt-2 " style={{width:Config_app.image_size_h2}} />
                                            <label>{installPc}</label>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <img alt='Hjelposlo' src={Config_app.img_app_pc} className="rounded pb-1 img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 text-center py-4'>
                                       
                                </div>
                            </div>

                        </div>
                        <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                            &nbsp;
                        </div>

                    </div>
                    
                </div>
            
            <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
                
                <Navbar op="user" />
            </div>
            </>
        )

    }
}