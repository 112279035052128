import React from 'react'
import { useEffect, useState } from "react";
import { Navbar } from "../components/navbar/Navbar"
import {signInWithPopup, signOut} from "firebase/auth";
import { auth, db, providerGoogle } from "../api/firebase";
import { clear_all, get_storage, set_storage } from "../api/Service";
import { Timestamp, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { Config_app } from "../styles/Config";
import { Banner } from '../components/Banner';
//import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'


export const User = () => {

    const [isLogin,setIsLogin] = useState(false)
    const [name, setName] = useState("")
    const [image, setImage] = useState("")
    const [email, setEmail] = useState("")
    const [estado, setEstado] = useState(0)
    const [showOm, setShowOm] = useState(false)

    const logout = async () => {
        
        await clear_all()
        await set_storage('politica',true)

        signOut(auth).then(() => {
        // Sign-out successful.


        window.location.reload()
        }).catch((error) => {
        // An error happened.
        });
    }

    const registrarUpdateUsuario = async (token) => {

        await set_storage('token', token)

    }

    const registrarNuevoUsuario = async (email) => {

        const token_storage = await get_storage('token')
        const docRef = doc(db, "users", token_storage);

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            const item = docSnap.data()

            let newDoc = docSnap.data()
            newDoc.fecha = Timestamp.now()
            newDoc.email = email
            newDoc.name = name
            newDoc.isLogin = true

            await setDoc(doc(db,'users', token_storage), newDoc)

            
        
        }

    }

    const registrarUserEmail = async (email) => {

        let q = query(collection(db, "users"), where("email", "==", email));

        const querySnapshot = await getDocs(q);

        if(querySnapshot.docs.length > 0){

            querySnapshot.forEach((doc) => { registrarUpdateUsuario(doc.id) });

        }
        else{

            registrarNuevoUsuario(email)

        }

    }

    const handleClickGoogle =()=>{
    
        signInWithPopup(auth, providerGoogle).then((data)=>{
            
            setIsLogin(data.user.email)
            localStorage.setItem("proveedor","google")
            localStorage.setItem("name",data.user.displayName)
            localStorage.setItem("email",data.user.email)
            localStorage.setItem("image",data.user.photoURL)
            localStorage.setItem("isLogin", true)
            registrarUserEmail(data.user.email)
        })
    
    }

    useEffect(()=>{
        setIsLogin(localStorage.getItem('isLogin'))
        setName(localStorage.getItem("name"))
        setImage(localStorage.getItem("image"))
        setEmail(localStorage.getItem("email"))
    },[])

    if(estado==0){

        return(
            <>
                <Banner
                    op="user" 
                    titulo={"user"}
                />
                <div className="conteiner">
                    <div className="row">
                        <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}} >
                            &nbsp;
                        </div>
                        <div className="col-12 col-md-8 col-lg-4 vh-100">
                            
                            {isLogin?
                                <div className="row py-5 px-3">
                                    <div className="col-12 mb-4 text-center">
                                        <h3>{name}</h3>
                                        <h6>{email}</h6>
                                    </div>
                                    <div className="col-12 mb-4 text-center">
                                        <img alt='Hjelposlo' className="img-fluid rounded" width={120} src={image} />
                                    </div>
                                    <div className="col-12 mb-4 text-center">
                                        <button className="btn btn-success" onClick={logout}>Salir</button>
                                    </div>
                                    <hr />
                                    <div className='col-12 d-flex w-100 flex-row justify-content-between align-items-center'>
                                            <h3 className='text-center'>Om oss</h3>
                                            {showOm==true&&<AiOutlinePlusCircle className='h2' onClick={()=>setShowOm(!showOm)} />}
                                            {showOm==false&&<AiOutlinePlusCircle className='h2' onClick={()=>setShowOm(!showOm)}  />}
                                        </div>
                                        {showOm&&<div className='col-12 p-2' style={{textAlign:'justify'}}>

                                        Hjelp Oslo er den ledende plattformen i Oslo, som gir essensiell støtte og informasjon om tjenester for både innvandrere, flyktninger, og lokale innbyggere som søker hjelp. Med informasjon på 130 språk, er vi dedikerte til å fjerne språkbarrierer og forenkle tilgangen til viktige ressurser og støttetjenester i byen. Vår mål er å styrke hele Oslos befolkning i deres søken etter hjelp, og å gjøre byen vår til et mer inkluderende og støttende samfunn for alle. Vi er her for deg - velkommen til Hjelp Oslo!
                                        </div>}
                                </div>
                                :
                                <>
                                    <div className="row py-5 px-3">
                                        <div className="col-12 mb-4 text-center">
                                            <img alt='Hjelposlo' className="img-fluid rounded" width={150} src={Config_app.logo_app_primary} />
                                        </div>
                                        <div className="col-12 mb-4 text-center">
                                            <h4>{Config_app.label_inicio_title_presentation}</h4>
                                        </div>
                                        <div className="col-12 mb-4 text-center">
                                            <button className="btn btn-success mb-3" onClick={handleClickGoogle}>Google</button>
                                        </div>
                                        <hr />
                                        <div className='col-12 d-flex w-100 flex-row justify-content-between align-items-center'>
                                            <h3 className='text-center'>Om oss</h3>
                                            {showOm&&<AiOutlineMinusCircle className='h2 pointer' onClick={()=>setShowOm(!showOm)} />}
                                            {showOm==false&&<AiOutlinePlusCircle className='h2 pointer' onClick={()=>setShowOm(!showOm)}  />}
                                        </div>
                                        {showOm&&<div className='col-12 p-2' style={{textAlign:'justify'}}>

                                        Hjelp Oslo er den ledende plattformen i Oslo, som gir essensiell støtte og informasjon om tjenester for både innvandrere, flyktninger, og lokale innbyggere som søker hjelp. Med informasjon på 130 språk, er vi dedikerte til å fjerne språkbarrierer og forenkle tilgangen til viktige ressurser og støttetjenester i byen. Vår mål er å styrke hele Oslos befolkning i deres søken etter hjelp, og å gjøre byen vår til et mer inkluderende og støttende samfunn for alle. Vi er her for deg - velkommen til Hjelp Oslo!
                                        </div>}
                                    </div>
                                    
                                </>
                            }

                        </div>
                        <div className="col-md-2 col-lg-4 d-none d-md-block" style={{background:"#00000007"}}>
                            &nbsp;
                        </div>

                    </div>
                </div>
            
            <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
                
                <Navbar op="user" />
            </div>
            </>
        )

    }
}