import React from 'react'
import { Config_app } from '../styles/Config';
import { Timestamp, addDoc, collection, getDocs, query } from 'firebase/firestore';
import { db } from './firebase';

export const get_storage = async ( name ) => {

    try {
        
        const dato = localStorage.getItem(name)
    
        const dato_json = dato != null ? JSON.parse(dato) : null
    
        if (dato_json !== null) {
    
            return dato_json;
          
        }
        else {
    
          return await registrarUser();
    
        }

    } catch (error) {
            
        return false;

    }
}

export const set_storage = async ( name, data ) => {

    try {

        const result = localStorage.setItem(name , JSON.stringify(data))

        if(result){
            
            return true

        }
        else{

            return false

        }
        
    } catch (error) {

        return false;
    
    }

}

export const get_random_code = async (max = 9999, min=1000) => {

    const result = Math.floor(Math.random() * (max - min + 1)) + min;

    return result;

}

export const get_email_template  = async (text="",code = "") => {


    const message_html = `<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Hjelposlo</title>
                <style>
                    .table{
                        width: 100%;
                        text-align: center;
                        vertical-align: middle;
                        margin: auto;
                        max-width: 500px;
                        background: #204420;
                        color: #ffffff;
                    }
                </style>
            </head>
            <body>
                <table class="table">
                    <body>
                        <tr>
                            <td>
                                <img src="https://firebasestorage.googleapis.com/v0/b/hjelposlo-4fb57.appspot.com/o/default%2Flogo.jpg?alt=media" width="150" height="150">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h2>Hjelposlo</h2>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3>`+text+" "+code+`</h3>
                            </td>
                        </tr>
                    </body>
                </table>
            </body>
        </html>`
    
        return message_html

}

export const clear_all = async () => {

    localStorage.clear()

    return

}


export const registrarUser = async () => {

    try {

        const labels = await get_labelsapp("no")

        let newUser = { 

            fecha: Timestamp.now(),
            language: Config_app.language_app,
            email:"",
            country:"",
            name:"",
            codigo: 1234,
            isLogin:false,
            favorite:[],
            labels: labels
        
        }

        const docRef = await addDoc( collection(db,'users'), newUser)

        // Datos basicos
        await set_storage('token', docRef.id)
        
        return docRef.id

    } catch (error) {

        return false
    
    }

}

export const get_labelsapp = async (language="no") => {

    let labels = {
        inicio_welcome: "Velkomen",
        inicio_question: "Hva trenger du?",
        buscar_placeholder: "Se etter",
        favorito_titulo: "Favoritter",
        favorito_mensaje: "Du er ikke registrert ennå.",
        favorito_login: "Jeg ønsker å registrere meg",
        usuario_nombre: "Navnet ditt",
        usuario_email: "E-post",
        usuario_pais: "Land",
        usuario_alert_yes: "Ja",
        usuario_alert_no: "Nei",
        usuario_alert_mensaje: "Du er ikke registrert. Vil du registrere deg?",
        usuario_mensaje_email: "Tu código de acceso es:",
        usuario_codigo: "Kode",
        usuario_enviar_codigo: "Send kode",
        usuario_confirmar_codigo: "bekrefte koden",
        usuario_salir: "Logg av",
        inicio_title_presentation:"Hjelposlo",
        inicio_subtitle_presentation:"VI BLOMSTRER SAMMEN",
        label_install_android: "Hvordan installere Hjelposlo på Android?",
        label_install_ios: "Hvordan installere Hjelposlo på Iphone?",
        label_install_pc: "Hvordan installere Hjelposlo på PC?"
    }

    const q = query(collection(db, "labelsapp"));
    
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {

        const label = doc.data()

        switch(label.name){
            case "inicio_welcome": {labels.inicio_welcome = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "inicio_question": {labels.inicio_question = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "buscar_placeholder": {labels.buscar_placeholder = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "favorito_titulo": {labels.favorito_titulo = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "favorito_mensaje": {labels.favorito_mensaje = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "favorito_login": {labels.favorito_login = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_nombre": {labels.usuario_nombre = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_email": {labels.usuario_email = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_pais": {labels.usuario_pais = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_alert_yes": {labels.usuario_alert_yes = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_alert_no": {labels.usuario_alert_no = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_alert_mensaje": {labels.usuario_alert_mensaje = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_mensaje_email": {labels.usuario_mensaje_email = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_codigo": {labels.usuario_codigo = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_enviar_codigo": {labels.usuario_enviar_codigo = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_confirmar_codigo": {labels.usuario_confirmar_codigo = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "usuario_salir": {labels.usuario_salir = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "install_android": {labels.install_android = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "install_ios": {labels.install_ios = (label.translated==false)?label.content:label.translated[0][language]};break;
            case "install_pc": {labels.install_pc = (label.translated==false)?label.content:label.translated[0][language]};break;

        }

    });

    return labels;
}

